import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/types/store/index'
import { DiscordState } from '@/types/store/discord'

const initialValues: DiscordState = {
  member404: false
}

const state = (): DiscordState => (initialValues)

const getters: GetterTree<DiscordState, RootState> = {}

const mutations: MutationTree<DiscordState> = {
  setMember404 (state, member404) {
    state.member404 = member404
  }
}

const actions: ActionTree<DiscordState, RootState> = {}

export const discord: Module<DiscordState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
